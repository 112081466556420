import React, { useState, createContext } from "react"
import { darkTheme } from "../../theme/color"

export const ThemeContext = createContext(darkTheme)

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(darkTheme)

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </ThemeContext.Provider>
  )
}

// Helper function to use ThemeContextProvider for gatsby-browser and gatsby-ssr
export default ({ element }) => (
  <ThemeContextProvider>{element}</ThemeContextProvider>
)
