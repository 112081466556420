import ThemeProvider from './src/components/stores/theme'
// custom typefaces
import "typeface-montserrat"
import 'typeface-roboto-slab'
import 'typeface-source-code-pro'
import "@fortawesome/fontawesome-svg-core/styles.css";

import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

export const wrapRootElement = ThemeProvider
