export const lightTheme = {
  vscode: "light",
  transparent: "rgba(0, 0, 0, 0)",
  background: "#f3f3f3",
  body: "#2C2E2F",
  heading: "#2C2E2F",
  link: "#8dcbf366",
  visitedLink: "#b98df366",
  subheading: "grey",
  border: "#8c8c8c",
  highlight: "#41b8ff",
  card: "#fdfbfb",
  cardHover: "#ebebeb",
  inlineCodeBackground: "#e0e1e28c",
  inlineCode: "#2C2E2F",
  cardShadow: "rgba(0,0,0,0.07)",
  codeHighlight: "#8498b124",
  yellow: "#f9d64f",
  blue: "#0b82b2",
  lineNumbers: "#c5c4c4",
}

export const darkTheme = {
  vscode: "dark",
  transparent: "rgba(255, 255, 255, 0)",
  background: "#2b2c2d",
  body: "#dddddd",
  heading: "#dddddd",
  link: "#264d6791",
  visitedLink: "#51266791",
  subheading: "grey",
  border: "#7d7d7d",
  highlight: "#0C87CF",
  card: "#1c1e1f",
  cardHover: "#151617",
  inlineCodeBackground: "#13111178",
  inlineCode: "#dddddd",
  cardShadow: "rgba(0,0,0,0.07)",
  codeHighlight: "#324e7124",
  yellow: "#f2ca2c;",
  blue: "#88d4f5",
  lineNumbers: "#565555",
}
